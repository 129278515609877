import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import toast from "@/utils/toast";
import eInvoiceStoreModule from "../eInvoiceStoreModule";

export default function useEInvoiceModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = "e-invoice-config";

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, eInvoiceStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);

  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async (bvModalEvt) => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }
    let data = {};
    Object.entries(itemLocal.value).forEach(([key, value]) => {
      if (key !== "id") data[key] = value;
    });

    isSubmitting.value = true;

    if (itemLocal.value?.id) {
      store
        .dispatch("e-invoice-config/update", { id: itemLocal.value.id, data })
        .then((response) => {
          toastification.showToastUpdateSuccess();
        })
        .then(() => {
          emit("refetch-data");
          refModal.value.toggle("#modal-e-invoice");
        })
        .catch((error) => {
          toastification.showToastError(error, refForm.value);
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    } else
      store
        .dispatch("e-invoice-config/create", data)
        .then((response) => {
          toastification.showToastCreateSuccess();
        })
        .then(() => {
          emit("refetch-data");
          refModal.value.toggle("#modal-e-invoice");
        })
        .catch((error) => {
          toastification.showToastError(error, refForm.value);
        })
        .finally(() => {
          isSubmitting.value = false;
        });
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
