import useJwt from "@/auth/jwt/useJwt";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    list(ctx, params) {
      return useJwt.getEInvoiceConfigData(params).then(response => response);
    },
    create(ctx, params) {
      return useJwt.createEInvoiceConfigData(params).then(response => response);
    },
    update(ctx, params) {
      return useJwt.updateEInvoiceConfigData(params).then(response => response);
    },
    active(ctx, params) {
      return useJwt.activeEInvoiceConfigData(params).then(response => response);
    },
    remove(ctx, params) {
      return useJwt.deleteEInvoiceConfigData(params).then(response => response);
    },
  },
};
