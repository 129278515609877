import { ref, watch, onUnmounted } from "@vue/composition-api";
import store from "@/store";

import toast from "@/utils/toast";
import eInvoiceStoreModule from "../eInvoiceStoreModule";

export default function useEInvoiceList() {
  const BED_STORE_MODULE_NAME = "e-invoice-config";

  // Register module
  if (!store.hasModule(BED_STORE_MODULE_NAME)) {
    store.registerModule(BED_STORE_MODULE_NAME, eInvoiceStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(BED_STORE_MODULE_NAME)) {
      store.unregisterModule(BED_STORE_MODULE_NAME);
    }
  });

  // Use toast
  const toastification = toast();

  const blankItem = {
    id: 0,
    name: "",
    phone: "",
    note: "",
    default: false,
    apartments: [],
  };

  const eInvoiceConfigItem = {
    id: 0,
    username: "",
    password: "",
    invoiceType: "",
    templateCode: "",
    invoiceSeries: "",
    sellerTaxCode: "",
    sellerLegalName: "",
    sellerAddressLine: "",
    sellerPhoneNumber: "",
    sellerEmail: "",
    sellerFaxNumber: "",
    sellerBankName: "",
    sellerBankAccount: "",
    sellerWebsite: "",
    provider: "viettel",
    autoIssueInvoice: false,
    default: false,
  };

  const item = ref(JSON.parse(JSON.stringify(eInvoiceConfigItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(eInvoiceConfigItem));
  };

  // Table Handlers
  const columns = [
    {
      label: "Sử dụng",
      field: "active",
      width: "100px",
    },
    {
      label: "Hành động",
      field: "action",
      width: "100px",
    },
    {
      label: "MST bên bán",
      field: "sellerTaxCode",
      width: "150px",
    },
    {
      label: "Tên doanh nghiệp",
      field: "sellerLegalName",
      width: "200px",
    },
    {
      label: "Số series hoá đơn",
      field: "invoiceSeries",
      width: "150px",
    },
    {
      label: "Mã hoá đơn",
      field: "templateCode",
      width: "120px",
    },
    {
      label: "Nhà cung cấp",
      field: "provider",
      width: "130px",
    },
    {
      label: "Mặc định",
      field: "default",
      width: "100px",
    },
  ];
  const rows = ref([]);

  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref("");
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: "",
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchEInvoiceConfig = () => {
    isLoading.value = true;
    store
      .dispatch("e-invoice-config/list", serverParams.value)
      .then((response) => {
        const { total, items } = response.data;
        rows.value = items;
        totalRecords.value = total;
        isLoading.value = false;
      })
      .catch((error) => {
        isLoading.value = false;
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchEInvoiceConfig();
  };

  const deleteEInvoiceConfig = (invoices) => {
    store
      .dispatch("e-invoice-config/remove", {
        ids: invoices.map((_obj) => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch((error) => {
        toastification.showToastError(error);
      });
  };

  const setDeleteEnable = (accounts) => {
    if (accounts.length === 0) {
      return false;
    }

    return accounts.filter((obj) => obj.active).length <= 0;
  };

  const handleChangeActive = (selectedInvoice) => {
    isLoading.value = true;

    store
      .dispatch("e-invoice-config/active", selectedInvoice.id)
      .then(() => {
        // fetchData();
        toastification.showToastSuccess("Đã cập nhật thành công");
      })
      .catch((error) => {
        fetchData();
        toastification.showToastError(error);
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  //   Methods
  const updateParams = (newProps) => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = (params) => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = (params) => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = (params) => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = (params) => {
    updateParams(params);
  };

  const selectionChanged = (params) => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = (val) => {
    item.value = val;
  };

  const onViewDetailItem = (val) => {
    item.value = val;
  };
  //   Watch
  // watch(searchTerm, (val) => {
  //   updateParams({ searchTerm: val });
  // });

  return {
    item,
    columns,
    rows,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    fetchData,
    fetchEInvoiceConfig,
    deleteEInvoiceConfig,
    resetItem,
    onEditItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onViewDetailItem,
    setDeleteEnable,
    handleChangeActive,
  };
}
