<template>
  <b-modal
    id="modal-e-invoice"
    ref="refModal"
    :title="t('Cấu hình hoá đơn điện tử')"
    :ok-title="t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    size="lg"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row class="mt-1">
          <b-col>
            <validation-provider
              #default="validationContext"
              name="autoNotificationNextBillingDateAt"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t("Tên đăng nhập") }}<span class="text-danger"> (*) </span>
                </template>
                <b-form-input
                  id="usernamae"
                  v-model="itemLocal.username"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="autoNotificationNextBillingDateAt"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t("Mật khẩu") }}<span class="text-danger"> (*) </span>
                </template>
                <b-form-input
                  id="password"
                  v-model="itemLocal.password"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="sellerTaxCode"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t("MST bên bán") }}<span class="text-danger"> (*) </span>
                </template>
                <b-form-input
                  id="sellerTaxCode"
                  v-model="itemLocal.sellerTaxCode"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <validation-provider
              #default="validationContext"
              name="invoiceType"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t("Loại hoá đơn") }}<span class="text-danger"> (*) </span>
                </template>
                <b-form-input
                  id="invoiceType"
                  v-model="itemLocal.invoiceType"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col>
            <validation-provider
              #default="validationContext"
              name="templateCode"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t("Mã hóa đơn") }}<span class="text-danger"> (*) </span>
                </template>
                <b-form-input
                  id="templateCode"
                  v-model="itemLocal.templateCode"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="invoiceSeries"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t("Số series hóa đơn")
                  }}<span class="text-danger"> (*) </span>
                </template>
                <b-form-input
                  id="invoiceSeries"
                  v-model="itemLocal.invoiceSeries"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <validation-provider
              #default="validationContext"
              name="sellerLegalName"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t("Tên doanh nghiệp") }}
                  <span class="text-danger"> (*) </span>
                </template>
                <b-form-input
                  id="sellerLegalName"
                  v-model="itemLocal.sellerLegalName"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="sellerAddressLine"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t("Địa chỉ bên bán")
                  }}<span class="text-danger"> (*) </span>
                </template>
                <b-form-input
                  id="sellerAddressLine"
                  v-model="itemLocal.sellerAddressLine"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col></b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <validation-provider
              #default="validationContext"
              name="sellerPhoneNumber"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t("SĐT bên bán") }}
                </template>
                <b-form-input
                  id="sellerPhoneNumber"
                  v-model="itemLocal.sellerPhoneNumber"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="sellerFaxNumber"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t("Số fax bên bán") }}
                </template>
                <b-form-input
                  id="sellerFaxNumber"
                  v-model="itemLocal.sellerFaxNumber"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="sellerEmail"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t("Email bên bán") }}
                </template>
                <b-form-input
                  id="sellerEmail"
                  v-model="itemLocal.sellerEmail"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <validation-provider
              #default="validationContext"
              name="sellerBankName"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t("Tên ngân hàng bên bán") }}
                </template>
                <b-form-input
                  id="sellerBankName"
                  v-model="itemLocal.sellerBankName"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="sellerBankAccount"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t("Số TK bên bán") }}
                </template>
                <b-form-input
                  id="sellerBankAccount"
                  v-model="itemLocal.sellerBankAccount"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="sellerWebsite"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t("Website bên bán") }}
                </template>
                <b-form-input
                  id="sellerWebsite"
                  v-model="itemLocal.sellerWebsite"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="12">
            <span>{{ t("Nhà cung cấp") }}</span>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-for="provider in providers"
                :key="provider.value"
                v-model="itemLocal.provider"
                :value="provider.value"
                class="custom-control-primary"
              >
                {{ t(provider.label) }}
              </b-form-radio>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="12">
            <span>{{
              t("Tự động xuất hóa đơn sau khi khách hàng thanh toán")
            }}</span>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="itemLocal.autoIssueInvoice"
                name="autoIssueInvoice"
                :value="true"
                class="custom-control-primary"
              >
                {{ t("Bật") }}
              </b-form-radio>

              <b-form-radio
                v-model="itemLocal.autoIssueInvoice"
                name="autoIssueInvoice"
                :value="false"
                class="custom-control-primary"
              >
                {{ t("Tắt") }}
              </b-form-radio>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <span>{{
                t("Mặc định")
              }}</span>
            <div class="demo-inline-spacing">
              <b-form-radio
                  v-model="itemLocal.default"
                  name="default"
                  :value="true"
                  class="custom-control-primary"
              >
                {{ t("Bật") }}
              </b-form-radio>

              <b-form-radio
                  v-model="itemLocal.default"
                  name="default"
                  :value="false"
                  class="custom-control-primary"
              >
                {{ t("Tắt") }}
              </b-form-radio>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BAlert,
  BImg,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormRadio,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BLink,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useEInvoiceModal from "./useEInvoiceModal";
import { providers } from "../constant";

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BAlert,
    BImg,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormRadio,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BLink,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  itemLocal() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils();

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
    } = useEInvoiceModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      providers,
      resetItemLocal,
      resetModal,
      onSubmit,
      resetForm,
      clearForm,
      refFormObserver,
      t,
      getValidationState,
    };
  },
};
</script>
